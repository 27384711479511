//import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;


document.addEventListener("DOMContentLoaded", function () {

  const processItems = document.querySelectorAll(".proces-item");
  const maskImage2 = document.querySelectorAll(".mask-image.v2");
  const processCirle = document.querySelector(".cirlce-proces-asset");
  const processes = document.querySelector(".processes");
  console.log(maskImage2.length);

  // Function to dynamically adjust observer options based on screen width
  function getObserverOptions() {
    let viewportWidth = window.innerWidth;

    if (viewportWidth < 768) { // Small screens (e.g. mobile)
      return {
        root: null,
        rootMargin: "0px", // Adjust root margin for mobile
        threshold: 0.2 // Slightly higher threshold for small screens
      };
    } else if (viewportWidth < 1024) { // Medium screens (e.g. tablets)
      return {
        root: null,
        rootMargin: "-20%", // Adjust root margin for tablets
        threshold: 0.15 // Adjust threshold for medium screens
      };
    } else { // Large screens (e.g. desktops)
      return {
        root: null,
        rootMargin: "-40%", // Original settings for large screens
        threshold: 0.1
      };
    }
  }

  // Function to create a new observer with dynamic options
  function createObservers() {
    const observerOptions = getObserverOptions();

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        const itemIndex = Array.from(processItems).indexOf(entry.target) + 1;
        if (entry.isIntersecting) {
          console.log('observer 1');
          entry.target.classList.add("in-view");
          processCirle.className = 'cirlce-proces-asset step3';
          processCirle.classList.add("s" + itemIndex);
          processes.className = 'processes d-flex flex-column col-12 col-lg-5';
          processes.classList.add("s" + itemIndex);
          entry.target.classList.add("in-view");
        }
      });
    }, observerOptions);

    processItems.forEach((item, index) => {
      if (index < processItems.length) {
        observer.observe(item);
      }
    });

    const lastItemObserverOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    };

    const lastItemObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          entry.target.classList.add("mostly-out-of-view");
          processCirle.classList.add("mostly-out-of-view");
        } else {
          console.log("Last item is back in view:", entry.target);
          entry.target.classList.remove("mostly-out-of-view");
          processCirle.classList.remove("mostly-out-of-view");
        }
      });
    }, lastItemObserverOptions);

    if (processItems.length > 0) {
      const lastItem = processItems[processItems.length - 1];
      lastItemObserver.observe(lastItem);
    }
  }

  // Create observers initially
  createObservers();

  // Add event listener for window resize to update observer options on screen size change
  window.addEventListener('resize', () => {
    createObservers(); // Recreate observers with updated options on resize
  });



  // // Define the observer options for general items
  // const observerOptionsV2 = {
  //   root: null, // Use the viewport as the root
  //   rootMargin: "0px 0px 0px 0px", // Trigger when the top of the element reaches the top of the viewport
  //   threshold: 0, // Trigger as soon as any part of the item is visible
  // };

  // // Observer for general items
  // const observerV2 = new IntersectionObserver((entries, observer) => {
  //   console.log('is this used?!<❤️ ❤️ ❤️ ❤️ ❤️ ❤️');
  //   entries.forEach((entry) => {
  //     const itemIndex = Array.from(processItems).indexOf(entry.target) + 1; // Get the index of the current item (1-based index)
  //     console.log(entry.boundingClientRect);
  //     if (entry.isIntersecting) {
  //       //maskImage.classList.add('start');
  //       console.log('koekoek');
  //       // Item is entering the viewport from the top
  //       // console.log(`Item ${itemIndex} entered the viewport:`, entry.target);
  //       entry.target.classList.add("start"); // Example: Add a class when in view
  //       // processCirle.classList.remove("s" + itemIndex); // Example: Add a class when in view
  //       //processCirle.className = 'cirlce-proces-asset step3';
  //       //processCirle.classList.add("s" + itemIndex); // Example: Add a class when in view
  //       //processes.className = 'processes d-flex flex-column col-5';
  //       //processes.classList.add("s" + itemIndex); // Example: Add a class when in view.classList.add("s" + itemIndex); // Example: Add a class when in view
  //       entry.target.classList.add("in-view"); // Example: Add a class when in view
  //       //console.log(entry.target.previousSibling);
  //     }
  //     else if (!entry.isIntersecting && entry.boundingClientRect.top > 0) {
  //       // Item is leaving the viewport on the way back down
  //       console.log(`Item ${itemIndex} left the viewport and is now above:`, entry.target);
  //       entry.target.classList.remove("in-view");
  //       entry.target.classList.remove("start");
  //     }
  //   });
  // }, observerOptionsV2);







  maskImage2.forEach((item, index) => {
    console.log(item);
    console.log(index);
    if (index <= processItems.length) {
      observerV2.observe(item);
    }
  });




});



document.addEventListener('DOMContentLoaded', function () {


  // const headerC = document.querySelector('.asset-header');
  // if (headerC) {
    
  //   var so = setTimeout(function () {
  //     console.log('asdasdasd');
  //     headerC.classList.add('go');
      
  //   }, 0);
  // }


 
  
  //get the image url
  let imgUrlElement = document.querySelector('.mask-image img');
  var parent_ = document.querySelectorAll('.mask-image');

  if (imgUrlElement) {
    let imgUrl = imgUrlElement.src;


    checkImageExists(imgUrl, (exists) => {
      if (exists) {
        console.log('Image exists:', imgUrl);
        parent_.forEach((item) => {
          if (item.classList.contains('v1')) {
            item.classList.add('start');
          }
            
          //
          item.addEventListener('click', () => {
            restartAnimation(item);
          });
        });


      } else {
        console.log('Image does not exist:', imgUrl);
        // You can perform a fallback action here, such as setting a default image
        imgUrlElement.src = 'path/to/fallback/image.jpg';
      }
    });
  } else {
    console.log('No image element found.');
  }


  function restartAnimation(ele) {
    ele.classList.remove('start');
    setTimeout(() => { ele.classList.add('start'); }, 10);
  }
  function checkImageExists(url, callback) {
    const img = new Image();


    img.onload = function () {
      callback(true);
    };


    img.onerror = function () {
      callback(false);
    };


    img.src = url;
  }
})






function getImageAspectRatio(imageSrc, callback) {
  const img = new Image();


  img.onload = function () {
    const aspectRatio = img.naturalWidth / img.naturalHeight;
    callback(null, aspectRatio); // Return the aspect ratio through the callback
  };


  img.onerror = function () {
    callback('Failed to load image.', null); // Handle errors through the callback
  };


  img.src = imageSrc;
}


// if you're using a bundler, first import:
import Headroom from "headroom.js";
// grab an element
var myElement = document.querySelector(".hektor-nav-wr");
// construct an instance of Headroom, passing the element
var headroom = new Headroom(myElement);
// initialise
headroom.init();





// import 'dark-mode-switch';
//import '/css/test.scss';


//alert('hoezo kappen hij doet yolo!!!');
//Import our custom CSS 
//import '../../css/src/main.scss';


//import './dark-mode-switch.js';




// import Swiper from 'swiper/bundle';
// //import Swiper from 'swiper';
// //* import the Swiper styles
// import 'swiper/css';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



// // import styles bundle
// import 'swiper/css/bundle';
// import { initWpSwiper } from './swiperModule'; // Adjust the path as necessary

document.addEventListener('DOMContentLoaded', function () {
  initWpSwiper(); // Initialize Swiper with the imported Swiper module
});



let swiper;
function innitSwiper() {

  swiper = new Swiper('.review', {
    // Optional parameters
    //direction: 'vertical',
    loop: false,
    autoHeight: true,
    //wrapperClass: 'wp-block-gallery',
    //slideClass: 'wp-block-image',
    //containerModifierClass:'wp-block-gallery',
    // If we need pagination
    centeredSlidesBounds: true,
    centeredSlides: true,
    
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 3,
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    //cssMode:true,
    keyboard: {
      enabled: true, // Enable keyboard control
    },
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },


    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true
    },
    on: {
      init: function () {
        // Swiper is ready

        console.log('Swiper is ready');
        //innitBackdrop();
      }
    }
  });

  swiper = new Swiper('.client', {
    modules: [Navigation, Pagination],
    //loop: true,
    //slidesPerView: 5,
    //spaceBetween: 20,
    //watchOverflow: true, 
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      200: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      // when window width is >= 480px
      // 480: {
      //   slidesPerView: 3,
      //   spaceBetween: 30
      // },
      // when window width is >= 640px
      800: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1350: {
        slidesPerView: 4,
        spaceBetween: 20
      },
      1550: {
        slidesPerView: 5,
        spaceBetween: 20
      }
    },
    //autoHeight: true,
    //wrapperClass: 'wp-block-gallery',
    //slideClass: 'wp-block-image',
    //containerModifierClass:'wp-block-gallery',
    // If we need pagination
    //centeredSlidesBounds: true,
    //centeredSlides: true,

    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 3,
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    //cssMode:true,
    keyboard: {
      enabled: true, // Enable keyboard control
    },
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },


    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true
    },
    on: {
      init: function () {
        // Swiper is ready

        console.log('Swiper is ready');
        //innitBackdrop();
      }
    }
  });




  // Function to go to a specific slide



  // Example usage:
  //goToSlide(5); // Go to slide index 2
  //innitBackdrop();
}


document.addEventListener('DOMContentLoaded', function () {
  innitSwiper();
});



console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode');
} else {
  console.log('Looks like we are in production mode');
}




import { AudioPlayer } from './audioPlayer.js'; // Adjust the path as necessary

document.addEventListener('DOMContentLoaded', () => {
  const audioPlayer = new AudioPlayer();
});

